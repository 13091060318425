<template>
  <div>
    <b-row class="profile-icons-svg">
      <b-col lg="4">
        <div class="iq-card">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">
                {{ $t("veterinary.bookings") }}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.135"
                height="25.135"
                viewBox="0 0 30.135 30.135"
              >
                <path
                  id="doctor-bag"
                  d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z"
                  transform="translate(-3 -3)"
                  fill="#646464"
                />
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">
              {{ pagination.totalItems }}
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <user-pages-title>
      {{ allInsurance }}
      <template v-slot:header-icon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32.483"
          height="32.483"
          viewBox="0 0 37.483 37.483"
        >
          <path
            id="_17e264738a73720c3ce88a761514fcfb"
            data-name="17e264738a73720c3ce88a761514fcfb"
            d="M16.78,1.781a3.282,3.282,0,0,1,5.079,0l1.627,1.988,2.4-.908a3.282,3.282,0,0,1,4.4,2.539L30.7,7.936l2.535.416a3.282,3.282,0,0,1,2.539,4.4l-.908,2.4,1.988,1.628a3.282,3.282,0,0,1,0,5.079L34.87,23.487l.908,2.4a3.282,3.282,0,0,1-2.539,4.4L30.7,30.7l-.415,2.535a3.282,3.282,0,0,1-4.4,2.539l-2.4-.908-1.627,1.988a3.282,3.282,0,0,1-5.079,0l-1.628-1.988-2.4.908a3.282,3.282,0,0,1-4.4-2.539L7.936,30.7,5.4,30.288a3.282,3.282,0,0,1-2.539-4.4l.908-2.4L1.781,21.859a3.282,3.282,0,0,1,0-5.079l1.988-1.628-.908-2.4A3.282,3.282,0,0,1,5.4,8.352l2.535-.416L8.351,5.4a3.282,3.282,0,0,1,4.4-2.539l2.4.908Zm1.239,24.442L29.505,14.737l-2.321-2.321L16.859,22.742l-5.4-5.4L9.135,19.66,15.7,26.223a1.641,1.641,0,0,0,2.321,0Z"
            transform="translate(-0.578 -0.579)"
            fill="#646464"
            fill-rule="evenodd"
          />
        </svg>
      </template>
      <template v-slot:header-title>
        <p class="text-muted font-size-22 m-0 p-0">
          {{ $t("userProfile.veterinaryDetails") }}
        </p>
      </template>
      <template v-slot:btn-name>
        <router-link
          :to="{ name: 'registerVeterinarian' }"
          class="d-flex align-items-center"
        >
          <i class="las la-plus-square text-white font-size-22"></i>
          <span class="font-size-16 text-white">{{
            $t("veterinary.BookAnAppointment")
          }}</span>
        </router-link>
      </template>
    </user-pages-title>
    <iq-card
      class="py-3 px-3 position-relative"
      v-for="(reservation, key) in allInsurance"
      :key="key"
      v-b-toggle="`more-content-${reservation.id}`"
      @click.native="toogleCards(reservation.id)"
    >
      <template>
        <b-row
          v-if="reservation.id !== selectedId"
          class="align-items-center pl-5"
        >
          <b-col lg="2">
            <div class="prod-img rounded overflow-hidden">
              <img :src="reservation.doctor.personal_picture" />
            </div>
          </b-col>
          <b-col lg="3">
            <div class="d-flex flex-column">
              <p class="m-0 p-0 font-size-16 text-secondary">
                {{ $t("veterinary.doctor") }}
              </p>
              <p class="m-0 p-0 font-size-18 text-muted">
                {{ reservation.doctor.full_name }}
              </p>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="d-flex align-items-start flex-column">
              <p class="m-0 p-0 font-size-16 text-secondary">
                {{ $t("veterinary.serviceType") }}
              </p>
              <p class="m-0 p-0 font-size-18 text-muted">
                {{ $t(`veterinary.${reservation.service_type}`) }}
              </p>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="d-flex align-items-start flex-column">
              <p class="m-0 p-0 font-size-16 text-secondary">
                {{ $t("veterinary.appointment") }}
              </p>
              <p class="m-0 p-0 font-size-18 text-muted">
                {{ reservation.time.day }} - {{ reservation.time.date }} -
                {{ reservation.time.time }}
              </p>
            </div>
          </b-col>
          <b-col lg="1">
            <div class="arrow-collapse-open">
              <i class="las la-angle-down text-secondary"></i>
            </div>
          </b-col>
          <div :class="`budge-label budge-label-${reservation.status}`">
            <p>{{ reservation.status }}</p>
          </div>
        </b-row>
        <b-row v-else class="align-items-center pl-5 mb-5">
          <b-col lg="2">
            <div class="prod-img">
              <img :src="reservation.doctor.personal_picture" />
            </div>
          </b-col>
          <b-col lg="3">
            <div class="d-flex flex-column">
              <p class="m-0 p-0 font-size-16 text-secondary">
                {{ $t("veterinary.doctor") }}
              </p>
              <p class="m-0 p-0 font-size-18 text-muted">
                {{ reservation.doctor.full_name }}
              </p>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="d-flex align-items-start flex-column">
              <p class="m-0 p-0 font-size-16 text-secondary">
                {{ $t("veterinary.serviceType") }}
              </p>
              <p class="m-0 p-0 font-size-18 text-muted">
                {{ $t(`veterinary.${reservation.service_type}`) }}
              </p>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="d-flex align-items-start flex-column">
              <p class="m-0 p-0 font-size-16 text-secondary">
                {{ $t("veterinary.appointment") }}
              </p>
              <p class="m-0 p-0 font-size-18 text-muted">
                {{ reservation.time.day }} - {{ reservation.time.date }} -
                {{ reservation.time.time }}
              </p>
            </div>
          </b-col>
          <b-col lg="1">
            <div
              :class="`budge-label-inside budge-label-inside-${reservation.status}`"
            >
              <p class="p-0 m-0">{{ reservation.status }}</p>
            </div>
          </b-col>
        </b-row>

        <b-collapse
          :id="`more-content-${reservation.id}`"
          class="p-0"
          accordion="my-accordion"
        >
          <div>
            <b-row v-if="reservation.id === selectedId">
              <b-col lg="12">
                <b-row>
                  <b-col md="4">
                    <div class="d-flex align-items-start flex-column">
                      <!-- <p class="m-0 p-0 font-size-16 text-secondary">
                        {{ $t("veterinary.appointment") }}
                      </p> -->
                      <!-- <p class="m-0 p-0 font-size-18 text-muted">
                        {{ reservation.time.day }} -
                        {{ reservation.time.date }} -
                        {{ reservation.time.time }}
                      </p> -->
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title text-secondary">
                        {{ $t("auth.address") }}
                      </h5>
                      <p class="m-0 p-0 font-size-18 text-muted">
                        {{ reservation.doctor.clinic_address }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <div class="d-flex align-items-start flex-column mb-4">
                      <p class="m-0 p-0 font-size-16 text-secondary">
                        {{ $t("auth.fullName") }}
                      </p>
                      <p class="m-0 p-0 font-size-18 text-muted">
                        {{ reservation.customer_name }}
                      </p>
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title text-secondary">
                        {{ $t("auth.phoneNumber") }}
                      </h5>
                      <p class="m-0 p-0 font-size-18 text-muted">
                        {{ reservation.customer_phone }}
                      </p>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <div class="d-flex align-items-start flex-column mb-4">
                      <h5 class="fees-company-item-title text-secondary">
                        {{ $t("veterinary.reservationPrice") }}
                      </h5>
                      <p class="m-0 p-0 font-size-22 text-warning">
                        <span>{{ reservation.price }}</span>
                        <span>{{ $t("veterinary.rs") }}</span>
                      </p>
                    </div>
                    <div class="d-flex justify-content-center">
                      <b-button variant="outline-warning" class="px-4 py-2 iq-border-radius-5 text-center" @click.prevent.self="downloadWasl(reservation)">
                        {{ $t('veterinary.receipt') }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-collapse>
        <span class="close-collapse" v-if="reservation.id === selectedId">
          <i class="las la-angle-up"></i>
        </span>
      </template>
    </iq-card>
    <div class="d-flex justify-content-center">
      <b-pagination
        v-if="pagination.totalItems > pagination.itemsPerPage"
        v-model="pagination.currentPage"
        :total-rows="pagination.totalItems"
        :per-page="pagination.itemsPerPage"
        first-number
        last-number
        class="mb-3 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        align="right"
        @input="getAllVeterinarian(pagination.currentPage)"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import userPagesTitle from '@/modules/userProfile/components/userPagesTitle'
import moment from 'moment'
import veterinarianServices from '@/modules/servicePages/veterinarian/services/veterinarian'

export default {
  name: "userVeterinary",
  components: { userPagesTitle },
  data() {
    return {
      visible: false,
      search: "",
      allInsurance: [],
      selectedId: "",
      pagination: {},
    };
  },
  methods: {
    downloadWasl (reservation) {
      if (reservation.invoice) {
        window.open(reservation.invoice, '_blank')
      } else {
      //  window.open(`${VUE_APP_VETERINARY_LINK}customer/reservations/receipt/${reservation.id}`)
        veterinarianServices.getInvoice(reservation.id).then(res => {
          window.open(res.data.url, '_blank')
        })
      }
    },
    getAllVeterinarian(page = 1) {
      veterinarianServices.getAllReservation(page).then((res) => {
        this.allInsurance = res.data.data;
        this.pagination = res.data.meta;
      });
    },
    toogleCards(id) {
      if (this.selectedId === id) {
        this.selectedId = "";
      } else {
        this.selectedId = id;
      }
    },
    ifBeforeNow(data) {
      const dateAfter24Hour = moment(data).add(24, "hours");
      const diff = moment().diff(dateAfter24Hour);
      if (diff >= 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {},
  created() {
    this.getAllVeterinarian();
  },
};
</script>

<style>
.font-size-25 {
  font-size: 25px !important;
}
.prod-img img {
  background-size: cover;
  width: 100%;
  height: 100%;
}
.budge-label {
  position: absolute;
  color: #fff;
  left: 0;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 40px;
}
.budge-label-inside {
  color: #fff;
  text-align: center;
  display: inline-block;
  padding: 0 20px;
}
.budge-label-active {
  background-color: #5dec9b;
}
.budge-label-inactive {
  background-color: #b72840;
}
.budge-label-inside-active {
  background-color: #5dec9b !important;
}
.budge-label-inside-inactive {
  background-color: #b72840 !important;
}
.budge-label p {
  transform: rotate(90deg);
  transform-origin: center;
  margin: 0 !important;
}
.close-collapse {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -20px;
  background-color: var(--iq-primary);
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  left: calc(50% - 20px);
}
.arrow-collapse-open {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.53);
  border-radius: 5px;
}
.budge-label-inside {
  background-color: #5dec9b;
  color: #fff;
  text-align: center;
  display: inline-block;
  padding: 0 20px;
}
</style>
